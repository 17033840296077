import React, { PureComponent } from 'react'
import { withPrefix } from 'gatsby'
import browserLang from 'browser-lang'
import { languages } from './locales'

class Redirect extends PureComponent {
  constructor(props) {
    super(props)

    const langKeys = languages.map(language => language.value)
    var { pathname } = props.location

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const { userAgent } = window.navigator

      const detected =
        window.localStorage.getItem('language') ||
        browserLang({
          languages: langKeys,
          fallback: 'en',
        })

      var ua = userAgent.toLowerCase()

      if (pathname === '/apps/') {
        if (ua.indexOf('macintosh') !== -1) {
          pathname += 'osx'
        } else if (ua.indexOf('iphone') !== -1) {
          pathname += 'ios'
        } else if (ua.indexOf('android') !== -1) {
          pathname += 'android'
        } else if (ua.indexOf('windows') !== -1) {
          pathname += 'windows'
        } else {
          pathname += 'osx'
        }
      }
      const newUrl = withPrefix(`/${detected}${pathname}`)
      window.localStorage.setItem('language', detected)
      window.location.replace(newUrl)
    }
  }

  render() {
    return <div />
  }
}

export default Redirect
